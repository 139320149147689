<template>
  <div id="newsStoryContent">
    <p id="Text1">
      The new minifigure Leveling system will arrive with the Power of the Nexus Force release!
      Leveling allows for a more streamlined game experience and allows for even more minifigure customization!
      If you missed our past article introducing the minifigure Leveling System, you’ll want to get caught up
      <router-link :to="{ name: '', params: {id: 335277}}">here</router-link>!
    </p>
    <p>
      Leveling up in LEGO Universe is as easy as pie.
      All you need to do is complete Missions and Achievements to raise your U-Score!
      But how much U-Score do you need exactly?
    </p>
    <table border="1">
      <tbody>
        <tr class="odd">
          <td align="CENTER"><b>Level</b></td>
          <td align="CENTER"><b>Required U-Score</b></td>
          <td align="CENTER"><b>Leveling Reward</b></td>
        </tr>
        <tr class="even">
          <td align="CENTER">5</td>
          <td align="CENTER">390</td>
          <td align="CENTER">4 Backpack Slots</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">10</td>
          <td align="CENTER">2,170</td>
          <td align="CENTER">Brown Shoulder Owl</td>
        </tr>
        <tr class="even">
          <td align="CENTER">15</td>
          <td align="CENTER">6,420</td>
          <td align="CENTER">6 Backpack Slots</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">20</td>
          <td align="CENTER">13,270</td>
          <td align="CENTER">Permanent Speed Boost</td>
        </tr>
        <tr class="even">
          <td align="CENTER">25</td>
          <td align="CENTER">24,520</td>
          <td align="CENTER">10 Backpack Slots</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">30</td>
          <td align="CENTER">40,870</td>
          <td align="CENTER">Brown Cape</td>
        </tr>
        <tr class="even">
          <td align="CENTER">35</td>
          <td align="CENTER">63,370</td>
          <td align="CENTER">20 Backpack Slots</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">40</td>
          <td align="CENTER">92,120</td>
          <td align="CENTER">Nexus Force Cape</td>
        </tr>
      </tbody>
    </table>
    <p>
      Existing minifigures, don’t fret!
      This experience chart is using a <i>newly adjusted U-Score system</i>.
      <b>The U-Score number that you see now in your Passport is not the number that you will see after this update!</b>
      All Missions and Achievements are readjusted to grant the proper amount of U-Score to fit in smoothly with this new leveling system.
      Here are some examples of the Missions and Achievements that have had the adjusted U-Score reward!
    </p>
    <table border="1">
      <tbody>
        <tr class="odd">
          <td align="CENTER"><b>Mission/Achievement Name</b></td>
          <td align="CENTER"><b>Current U-Score Reward</b></td>
          <td align="CENTER"><b>Adjusted U-Score Reward</b></td>
        </tr>
        <tr class="even">
          <td align="CENTER">Ain't Misbehavin' 1</td>
          <td align="CENTER">5</td>
          <td align="CENTER">40</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">Ain't Misbehavin' 2</td>
          <td align="CENTER">10</td>
          <td align="CENTER">90</td>
        </tr>
        <tr class="even">
          <td align="CENTER">Ain't Misbehavin' 3</td>
          <td align="CENTER">15</td>
          <td align="CENTER">160</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">Ain't Misbehavin' 4</td>
          <td align="CENTER">20</td>
          <td align="CENTER">260</td>
        </tr>
        <tr class="even">
          <td align="CENTER">Nexus Force Commendation 1</td>
          <td align="CENTER">5</td>
          <td align="CENTER">20</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">Nexus Force Commendation 2</td>
          <td align="CENTER">10</td>
          <td align="CENTER">60</td>
        </tr>
        <tr class="even">
          <td align="CENTER">Nexus Force Commendation 3</td>
          <td align="CENTER">20</td>
          <td align="CENTER">115</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">Nexus Force Commendation 4 *NEW*</td>
          <td align="CENTER">-</td>
          <td align="CENTER">250</td>
        </tr>
        <tr class="even">
          <td align="CENTER">Nexus Force Commendation 5 *NEW*</td>
          <td align="CENTER">-</td>
          <td align="CENTER">385</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">The Scrolls of Ninjago</td>
          <td align="CENTER">5</td>
          <td align="CENTER">310</td>
        </tr>
      </tbody>
    </table>
    <p>
      Existing minifigures will be transitioned to this new system.
      The first time you enter LEGO Universe after this update, your minifigure’s level will automatically be generated using your existing U-Score.
      Missions and Achievements that you have already completed will be converted to the new adjusted U-Score reward!
      You don’t need to do a thing - your minifigure will automatically level up to the correct level and you will instantly get all leveling rewards for previously earned milestone levels!
    </p>
    <p>
      With this new update, another change is happening.
      Items will now have level requirements.
      This means that you must be the specified level or higher in order to unlock the item to use or equip it.  Here are some examples of item requirements:
    </p>
    <table border="1">
      <tbody>
        <tr class="odd">
          <td align="CENTER"><b>Item</b></td>
          <td align="CENTER"><b>Required Level</b></td>
        </tr>
        <tr class="even">
          <td align="CENTER">Rank 1 Book</td>
          <td align="CENTER">5</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">Rank 1 Gear</td>
          <td align="CENTER">5</td>
        </tr>
        <tr class="even">
          <td align="CENTER">Rank 2 Book</td>
          <td align="CENTER">10</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">Rank 2 Gear</td>
          <td align="CENTER">15</td>
        </tr>
        <tr class="even">
          <td align="CENTER">Rank 3 Book</td>
          <td align="CENTER">25</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">Rank 3 Gear</td>
          <td align="CENTER">30</td>
        </tr>
        <tr class="even">
          <td align="CENTER">Spike Hammer</td>
          <td align="CENTER">30</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">Valiant Weapons</td>
          <td align="CENTER">35</td>
        </tr>
        <tr class="even">
          <td align="CENTER">Bat Lord Set</td>
          <td align="CENTER">35</td>
        </tr>
        <tr class="odd">
          <td align="CENTER">Valiant Capes</td>
          <td align="CENTER">40</td>
        </tr>
      </tbody>
    </table>
    <p>
      If you happen to be below the level required, never fear, you will not lose the item!
      You can still pick up the item if it was a monster drop, you can store it in your Vault, or you can trade it to a fellow minifigure just like before!
    </p>
    <p>
      Existing minifigures, be sure to check your Backpack and Vault items and <b>link your items!</b>
      If you link your item before the Power of the Nexus Force update hits LEGO Universe, you will be able to use your item – <i>regardless of the level requirement!</i>
    </p>
    <p>
      By leveling up, you unlock the power to use new items and equipment, but that’s not all!
      Every 5 levels, your minifigure will earn cool new Leveling Rewards!
      At level 30, you will get your first Cape as a leveling reward!
      You can now also purchase basic Capes in all colors of the rainbow to match any outfit!
      Keep on leveling and at level 40, you will get the Nexus Force Cape, which gives an astounding 3 Armor Points, 3 Imagination Points and 1 Life Point!
      You have also unlocked Valiant Capes, which are available for the original Faction Kits and can be purchased at your Faction Vendor in Nexus Tower.
    </p>
    <p>
      Like Valiant Weapons, Valiant Capes also count towards your Faction Kit Multi-Item Bonus! Valiant Capes take your shoulder slot, allowing for more customization than ever before!
    </p>
    <table border="0">
      <tbody>
        <tr class="odd">
          <td align="CENTER">
            <!-- picA10F13A6FDB52B861739001B33FD8FE9.jpg -->
            <a :href="engineerDetail">Click for more info!</a>
          </td>
          <td align="CENTER">
            <!-- picCA2D3A26E01881054D1B5A7297207892.jpg -->
            <a :href="summonerDetail">Click for more info!</a>
          </td>
        </tr>
        <tr class="even">
          <td align="CENTER">
            <!-- picA10F13A6FDB52B861739001B33FD8FE9.jpg -->
            <a :href="engineerDetail" class="rounded">
              <!-- pic4575FBB3EFD80048F4BE4CD0E53F8691.jpg -->
              <img src="@/assets/news-network/valiant-cape-engineer.jpg" class="rounded">
            </a>
          </td>
          <td align="CENTER">
            <!-- picCA2D3A26E01881054D1B5A7297207892.jpg -->
            <a :href="summonerDetail" class="rounded">
              <!-- picD7C0C1A324DC5CF4C11E9C74B03869F0.jpg -->
              <img src="@/assets/news-network/valiant-cape-summoner.jpg" class="rounded">
            </a>
          </td>
        </tr>
      </tbody>
    </table>


    <table border="0">
      <tbody>
        <tr class="odd">
          <td align="CENTER">
            <!-- pic9A5FC2E2E86CB5E44AABF4C0B2A9982E.jpg -->
            <a :href="sorcererDetail">Click for more info!</a>
          </td>
          <td align="CENTER">
            <!-- picDB9E3C777E7AA0D6A9CF7081797D5B41.jpg -->
            <a :href="spaceMarauderDetail">Click for more info!</a>
          </td>
        </tr>
        <tr class="even">
          <td align="CENTER">
            <!-- pic9A5FC2E2E86CB5E44AABF4C0B2A9982E.jpg -->
            <a :href="sorcererDetail" class="rounded">
              <!-- pic89144B4395001D732823BEBCDC3DE962.jpg -->
              <img src="@/assets/news-network/valiant-cape-sorcerer.jpg" class="rounded">
            </a>
          </td>
          <td align="CENTER">
            <!-- picDB9E3C777E7AA0D6A9CF7081797D5B41.jpg -->
            <a :href="spaceMarauderDetail" class="rounded">
              <!-- picE5B338D3720FC9AB973ED3A3275C0774.jpg -->
              <img src="@/assets/news-network/valiant-cape-space-marauder.jpg" class="rounded">
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <table border="0">
      <tbody>
        <tr class="odd">
          <td align="CENTER">
            <!-- picCACA6F6DD387A4FF6D4EE2C6B628153C.jpg -->
            <a :href="knightDetail">Click for more info!</a>
          </td>
          <td align="CENTER">
            <!-- pic1173B40335C984A195A8A3C393E08434.jpg -->
            <a :href="samuraiDetail">Click for more info!</a>
          </td>
        </tr>
        <tr class="even">
          <td align="CENTER">
            <!-- picCACA6F6DD387A4FF6D4EE2C6B628153C.jpg -->
            <a :href="knightDetail" class="rounded">
              <!-- pic7134C045C2008391842E37126F566E66.jpg -->
              <img src="@/assets/news-network/valiant-cape-knight.jpg" class="rounded">
            </a>
          </td>
          <td align="CENTER">
            <!-- pic1173B40335C984A195A8A3C393E08434.jpg -->
            <a :href="samuraiDetail" class="rounded">
              <!-- picCE322D26093036FEB057B372D2599303.jpg -->
              <img src="@/assets/news-network/valiant-cape-samurai.jpg" class="rounded">
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <table border="0">
      <tbody>
        <tr class="odd">
          <!-- picA58C2E7C872E4617FBDAF2FC5B719444.jpg -->
          <td align="CENTER"><a :href="buccaneerDetail">Click for more info!</a></td>
          <!-- pic9A6D08B095181BD6A848902A5C9DD785.jpg -->
          <td align="CENTER"><a :href="daredevilDetail">Click for more info!</a></td>
        </tr>
        <tr class="even">
          <td align="CENTER">
            <!-- picA58C2E7C872E4617FBDAF2FC5B719444.jpg -->
            <a :href="buccaneerDetail" class="rounded">
              <!-- pic536395D2FD357B50BA1FE3C98ACEAFA4.jpg -->
              <img src="@/assets/news-network/valiant-cape-buccaneer.jpg" class="rounded">
            </a>
          </td>
          <td align="CENTER">
            <!-- pic9A6D08B095181BD6A848902A5C9DD785.jpg -->
            <a :href="daredevilDetail" class="rounded">
              <!-- pic4368E2E92EF9A05CEC84F29DDA32FF21.jpg -->
              <img src="@/assets/news-network/valiant-cape-daredevil.jpg" class="rounded">
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      The new minifigure leveling system and capes is coming to LEGO Universe very soon.
      Make sure you link your items and familiarize yourself with your Passport.
      Be sure to check back here for more details soon!
    </p>
    <p>
      Discuss this article on the <router-link href="/messageboards/4064304">message boards</router-link>!
    </p>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        engineerDetail: require("@/assets/news-network/detail/valiant-cape-engineer.jpg"),
        summonerDetail: require("@/assets/news-network/detail/valiant-cape-summoner.jpg"),
        sorcererDetail: require("@/assets/news-network/detail/valiant-cape-sorcerer.jpg"),
        spaceMarauderDetail: require("@/assets/news-network/detail/valiant-cape-space-marauder.jpg"),
        knightDetail: require("@/assets/news-network/detail/valiant-cape-knight.jpg"),
        samuraiDetail: require("@/assets/news-network/detail/valiant-cape-samurai.jpg"),
        buccaneerDetail: require("@/assets/news-network/detail/valiant-cape-buccaneer.jpg"),
        daredevilDetail: require("@/assets/news-network/detail/valiant-cape-daredevil.jpg"),
      }
    }
  }
</script>

<style scoped lang="scss">
  #newsStoryContent img {
    width: 220px;
    height: 192px;
  }
</style>
